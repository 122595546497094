import React from "react";
import "./Testimonial.scss";

interface TestimonialProps {
  name: string;
  description: string;
  customerPhoto: string;
  designation: string;
}

const Testimonial: React.FC<TestimonialProps> = (props) => {
  return (
    <div className="d-flex testimonial-wrapper d-flex">
      <p className="testimonial-description m-0">{props.description}</p>
      <hr className="w-50 only-mobile m-0 mt-4 mb-4 mr-auto" />
      <div className="testimonial-user d-flex">
        <img
          className="testimonial-user-photo"
          src={props.customerPhoto}
          alt={props.name}
        />
        <div>
          <h1>{props.name}</h1>
          <h3>{props.designation}</h3>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
