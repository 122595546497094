import React from "react";
import "./MainNavBar.scss";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import useToggler from "../../hooks/toggler";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";

interface MainNavBarProps {
  onlyLogo?: boolean;
  lightLogo?: boolean;
  width?: number;
}

const HELLO_AR_LOGO_DARK = "/images/logo-dark.svg";
const HELLO_AR_LOGO_LIGHT = "/images/logo.svg";

const routes = [
  {
    name: "Features",
    path: "/360-models",
  },
  {
    name: "Case studies",
    path: "/case-studies",
  },
  { name: "Try it", path: "/" },
  { name: "Blog", path: "https://blog.helloar.io/" },
  { name: "About us", path: "/about-us" },
];
const MainNavBar: React.FC<MainNavBarProps> = (props) => {
  const { onlyLogo, lightLogo = true, width } = props;
  const menuToggler = useToggler(false);
  return (
    <div className="main-navbar-wrapper">
      <Container className="main-navbar-container">
        <div className="main-navbar-left">
          <Link to="/">
            <img
              src={lightLogo ? HELLO_AR_LOGO_LIGHT : HELLO_AR_LOGO_DARK}
              alt="helloar-logo"
              className="main-navbar-logo"
            />
          </Link>
        </div>
        {!onlyLogo && (
          <React.Fragment>
            <div className="main-navbar-center only-desktop">
              {routes.map((route) => (
                <Link to={route.path} className="link-primary">
                  {route.name}
                </Link>
              ))}
            </div>
            <button
              className="menu-button text-light only-tablet"
              onClick={menuToggler.on}
            >
              <AiOutlineMenu size={25} />
            </button>
            <Link to="/contact-us" className="only-desktop">
              <button className="button-primary px-5">Request a demo</button>
            </Link>
          </React.Fragment>
        )}
      </Container>
      {menuToggler.isOn && (
        <div className={`main-navbar-mobile-sidebar-backdrop`}></div>
      )}
      {menuToggler.isOn && (
        <div
          className={`main-navbar-mobile-sidebar only-tablet ${
            menuToggler.isOn
              ? "main-navbar-mobile-sidebar-open"
              : "main-navbar-mobile-sidebar-close"
          }`}
        >
          <button className="text-light ml-auto" onClick={menuToggler.off}>
            <AiOutlineClose size={25} />
          </button>
          {routes.map((route) => (
            <Link
              to={route.path}
              onClick={menuToggler.off}
              className="link-primary"
            >
              {route.name}
            </Link>
          ))}
          <Link to="/contact-us">
            <button
              className="button-primary bg-light"
              style={{ color: "var(--helloar)" }}
            >
              Request a demo
            </button>
          </Link>
        </div>
      )}
      {width !== undefined && width > 0 && (
        <div
          className="line-loader"
          style={{
            background: "var(--helloar)",
            height: 4,
            width: `${props.width}%`,
          }}
        ></div>
      )}
    </div>
  );
};

export default MainNavBar;
