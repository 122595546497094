import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.scss";
import { AiOutlineTwitter, AiOutlineLinkedin } from "react-icons/ai";
import { RiFacebookFill } from "react-icons/ri";

interface FooterProps {}

const socials = [
  {
    url: "https://www.facebook.com/helloario/",
    name: "facebook",
    icon: <RiFacebookFill size={25} />,
  },
  {
    url: "https://twitter.com/helloario",
    name: "twitter",
    icon: <AiOutlineTwitter size={25} />,
  },
  {
    url: "https://in.linkedin.com/company/helloar",
    name: "linkedin",
    icon: <AiOutlineLinkedin size={25} />,
  },
];
const Footer: React.FC<FooterProps> = (props) => {
  return (
    <footer className="footer-container">
      <Container className="footer-content d-flex ">
        <div className="footer-left">© 2023 HelloAR. All rights reserved</div>
        <div className="footer-right d-flex">
          {socials.map((item, index) => (
            <a
              className="link-primary social-link"
              key={index}
              href={item.url}
              rel="noreferrer"
              target="_blank"
            >
              {item.icon}
            </a>
          ))}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
