import { PageProps } from "gatsby";
import React, { useEffect } from "react";
import Footer from "../Footer";
import MainNavBar from "../MainNavBar/MainNavBar";
import "./MainLayout.scss";

interface MainLayoutProps {
  path?: string;
  hideFooter?: boolean;
  width: number;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  path,
  hideFooter,
  children,
  width,
}) => {
  useEffect(() => {
    const htmlElement = document.getElementsByTagName("html")[0];
    if (htmlElement) {
      if (path === "/") htmlElement.style.overflow = "hidden";
      else htmlElement.style.overflow = "auto";
    }
  }, [path]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: "1 1 auto",
        zIndex: 100,
      }}
    >
      <MainNavBar width={width} />
      <div
        className={`position-relative d-flex flex-column pointer-events-none bg-white`}
        style={{
          flex: "1 1 auto",
        }}
      >
        {children}
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
};

export default MainLayout;
